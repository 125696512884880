<template>
    <span v-if="store.getters.loggedIn">
        <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app >
            <v-list dense v-if="store.state.role == 'transporter'">
                <template v-for="(item, j ) in transport">
                    <v-list-group v-if="item.children" :key="item.text" v-model="item.model" 
                    :prepend-icon="item.Ficon" :append-icon="item.icon" color="blue-grey darken-3">
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title class="fontSetting">{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-divider :key="j" v-if="item.model == true"></v-divider>
                        <router-link v-for="(child, i) in item.children" :key="i" link :to="{name:child.link}" class="SidebarLink">
                            <v-list-item>
                                <v-list-item-action v-if="child.icon">
                                    <v-icon>{{ child.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title class="fontSetting">{{ child.text }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </router-link>
                        <v-divider></v-divider>
                    </v-list-group>
                    <router-link v-else :key="j" link :to="{name:item.link}" class="SidebarLink">
                        <v-list-item >
                            <v-list-item-action>
                                <v-icon>{{ item.Ficon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="fontSetting">
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                </template>
            </v-list>
            <v-list dense v-else>
                <template v-for="(item, j ) in items">
                    <v-list-group v-if="item.children" :key="item.text" v-model="item.model" 
                    :prepend-icon="item.Ficon" :append-icon="item.icon" color="blue-grey darken-3">
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title class="fontSetting">{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-divider :key="j" v-if="item.model == true"></v-divider>
                        <router-link v-for="(child, i) in item.children" :key="i" link :to="{name:child.link}" class="SidebarLink">
                            <v-list-item>
                                <v-list-item-action v-if="child.icon">
                                    <v-icon>{{ child.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title class="fontSetting">{{ child.text }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </router-link>
                        <v-divider></v-divider>
                    </v-list-group>
                    <router-link v-else :key="j" link :to="{name:item.link}" class="SidebarLink">
                        <v-list-item >
                            <v-list-item-action>
                                <v-icon>{{ item.Ficon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="fontSetting">
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                </template>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue-grey darken-3" dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
                <span class="hidden-sm-and-down">e-Transportation</span>
            </v-toolbar-title>
            <v-spacer />
            <v-btn color="blue-grey lighten-1" dark v-if="store.state.role == 'admin' || store.state.role == 'manager'" @click="createBox=true"> Daily Updates</v-btn>
            <v-spacer />
            <Notification></Notification>
            <v-btn text>
                {{store.state.uName}}
                <v-avatar size="32px" class="ml-2">
                    <v-img src="/images/user.png"></v-img>
                </v-avatar>
            </v-btn>
        </v-app-bar>
        <!-- New Bid Start -->
        <v-dialog v-model="createBox" scrollable persistent max-width="800px" transition="dialog-transition">
            <newBid></newBid>
        </v-dialog>
        <!-- Notifications -->
        <v-snackbar v-model="notificationBar" :timeout="10000" top center :color="notificationColor" multi-line class="mt-70"> {{ notificationText }} <v-btn color="white" text fab @click="notificationBar = false"><v-icon>mdi-close</v-icon> </v-btn></v-snackbar>
        <!-- New Bid End -->
         <notifications group="Notification" position="bottom right">
            <template slot="body" class="vue-notification">
                <v-card-text v-if="notifyData" class="relativeset">
                    <label class="bellIcon"><v-icon color="red" large>mdi-bell-ring</v-icon></label>
                    <div class="notification-title text-center pb-2 red--text">{{notifyData.Product}}</div>
                    <div class="notification-content text-center">
                        <p class="text-center font-weight-bold text-uppercase my-0">{{notifyData.message}}</p>
                        <!--updateNew newOrder approveOrder finalOrder-->
                        <span v-if="notifyData.notificationType == 'approveOrder'">
                            <p class="my-0 text-center text-uppercase">From: {{notifyData.fromLocation}}</p>
                            <p class="my-0 text-center text-uppercase">To: {{notifyData.toLocation}}</p>
                            <p class="my-0 text-center text-uppercase">Qty: {{notifyData.update.qty}}/tons</p>
                            <p class="my-0 text-center text-uppercase">Final Rate: ₹ {{notifyData.update.fRate}}/-</p>
                            <v-chip round class="text-uppercase my-2 font-weight-bold" color="green" text-color="white" small> {{notifyData.update.status}} </v-chip>
                        </span>
                        <span v-if="notifyData.notificationType == 'finalOrder'">
                            <p class="my-0 text-center text-uppercase">From: {{notifyData.fromLocation}}</p>
                            <p class="my-0 text-center text-uppercase">To: {{notifyData.toLocation}}</p>
                            <p class="my-0 text-center text-uppercase">Qty: {{notifyData.update.qty}}/tons</p>
                            <p class="my-0 text-center text-uppercase">Your's Rate: ₹ {{notifyData.update.rate}}/-</p>
                            <p class="my-0 text-center text-uppercase">Final Rate: ₹ {{notifyData.update.fRate}}/-</p>
                            <v-chip round class="text-uppercase my-2 font-weight-bold" color="orange" text-color="black" small> {{notifyData.update.status}} </v-chip>
                        </span>
                        <span v-if="notifyData.notificationType == 'newOrder'">
                            <p class="my-0 text-center text-uppercase">From: {{notifyData.fromLocation}}</p>
                            <p class="my-0 text-center text-uppercase">To: {{notifyData.toLocation}}</p>
                            <p class="my-0 text-center text-uppercase">Qty: {{notifyData.update.qty}}/tons</p>
                            <p class="my-0 text-center text-uppercase">Offer Rate: ₹ {{notifyData.update.rate}}/-</p>
                            <v-chip round class="text-uppercase my-2 font-weight-bold" color="orange" text-color="black" small> {{notifyData.update.status}} </v-chip>
                        </span>
                        <span v-if="notifyData.notificationType == 'updateNew'">
                            <span>
                                <p class="my-0 text-center text-uppercase">From: {{notifyData.fromLocation}}</p>
                                <p class="my-0 text-center text-uppercase">To: {{notifyData.toLocation}}</p>
                                <p class="my-0 text-center text-uppercase">Qty: {{notifyData.update.qty}}/tons</p>
                            </span>
                        </span>
                    </div>
                </v-card-text>
            </template>
        </notifications>
    </span>
</template>

<script>
import Notification from '../../components/notification/index.vue'
import {bus} from '../../main'
import {Howl, Howler} from 'howler';
import {store} from '../../store'
import newBid from '../dailyupdate/index.vue'
import Config from '../../config'
export default {
    created(){
        this.listen();
        window.addEventListener('keydown', event => {
            if(event.shiftKey && event.which === 68){
                this.createBox = true;
            }
        });

        bus.$on('close',() =>  {
            this.createBox = false;
        });

        bus.$on('error', error =>  {
            //alert('s');
            this.notificationBar = true;
            this.notificationColor = 'red';
            this.notificationText = Config.httpError(error);
        });

        bus.$on('response', data =>  {
            this.notificationBar = true;
            this.notificationColor = data.res.action == true ? 'black' : 'red';
            this.notificationText = data.res.message;
        });           
    },
    methods:{
        listen(){
            var vm = this;
            var channel = this.$pusher.subscribe("private-App.Models.User."+this.$store.state.userId);
            channel.bind("Illuminate\\Notifications\\Events\\BroadcastNotificationCreated", function (data){
                vm.notifyData = data;
                vm.$notify({
                    group: 'Notification',
                    type: 'BlackBG',
                    duration: 10000,
                    speed: 1000,
                });
                vm.NotificationSound();
                bus.$emit('notify', true);
            });

            
        },
        NotificationSound(){
            const sound = new Howl({
                src: ['/notification/notification.mp3']
            });
            sound.play();
            Howler.volume(1.0);
        },
    },
    components:{newBid,Notification},
    props: {
      source: String,
    },
    data: () => ({
        notifyData:'',
        store:store,
        dialog: false,
        drawer: null,
        items: store.state.role == 'admin' ? [
            { Ficon: 'mdi-view-dashboard', text: 'Dashboard', link:'dashboard'},
            { Ficon: 'mdi-checkbox-blank-outline', text: 'Products', link:'product'},
            { Ficon: 'mdi-map-marker', text: 'Location', link:'location'},
            { Ficon: 'mdi-truck', text: 'Vehicle', link:'vehicle'},
            { Ficon: 'mdi-chart-line', text: 'Report', link:'report'},
            { Ficon: 'mdi-account-key', text: 'Users', link:'user'},
            { Ficon: 'mdi-wrench', text: 'Settings', link:'setting'},
            { Ficon: 'mdi-logout-variant', text: 'Logout', link:'logout'},
            /* {
                Ficon:'mdi-account-key',
                icon: 'mdi-chevron-down', 'icon-alt': 'mdi-chevron-up',
                text: 'User',
                children: [
                    {text: 'Users', link:'dashboard'},
                    {text: 'Roles', link:'dashboard'},
                    {text: 'ACLS', link:'dashboard'},
                ],
            },*/
        ] : [
            { Ficon: 'mdi-view-dashboard', text: 'Dashboard', link:'dashboard'},
            { Ficon: 'mdi-checkbox-blank-outline', text: 'Products', link:'product'},
            { Ficon: 'mdi-map-marker', text: 'Location', link:'location'},
            { Ficon: 'mdi-truck', text: 'Vehicle', link:'vehicle'},
            { Ficon: 'mdi-chart-line', text: 'Report', link:'dashboard'},
            { Ficon: 'mdi-wrench', text: 'Settings', link:'setting'},
            { Ficon: 'mdi-logout-variant', text: 'Logout', link:'logout'},
        ],
        transport:[
            { Ficon: 'mdi-view-dashboard', text: 'Dashboard', link:'tdashboard'},
            { Ficon: 'mdi-wrench', text: 'Settings', link:'setting'},
            { Ficon: 'mdi-logout-variant', text: 'Logout', link:'logout'},
            /* {
                Ficon:'mdi-account-key',
                icon: 'mdi-chevron-down', 'icon-alt': 'mdi-chevron-up',
                text: 'User',
                children: [
                    {text: 'Users', link:'dashboard'},
                    {text: 'Roles', link:'dashboard'},
                    {text: 'ACLS', link:'dashboard'},
                ],
            },*/
        ],
        //Notifications
        notificationBar:false,
        notificationColor:'black',
        notificationText:'',
        // Dialog
        createBox:false,
    }),
  }
</script>
<style scoped>
.SidebarLink{
    text-decoration:none !important;
}
.v-list .router-link-exact-active{
    display: block;
    background:rgba(55, 71, 79, 0.07);
}
.fontSetting{
    font-size: 14px !important;
}
</style>