<template>
    <v-card v-if="windowLoad == true">
        <v-skeleton-loader type="article"></v-skeleton-loader>
        <v-skeleton-loader type="article"></v-skeleton-loader>
        <v-skeleton-loader type="actions"></v-skeleton-loader>
    </v-card>
    <v-card v-else>
        <v-app-bar  dark color="blue-grey darken-3" dense flat>
            <v-toolbar-title>
                Vehicle Detail
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" text fab x-small @click="close()"><v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>
        <v-card-text class="pt-5">
            <v-text-field v-model="vNumber" label="Vehicle Number" outlined color="blue-grey darken-3" dense hide-details></v-text-field>
        </v-card-text>
        <v-card-text>
            <v-text-field v-model="dNumber" label="Driver Mobile Number" outlined color="blue-grey darken-3" dense hide-details></v-text-field>
        </v-card-text>
        <v-card-text>
            <v-text-field v-model="rTime" label="Vehicle Reporting Time" outlined color="blue-grey darken-3" dense hide-details></v-text-field>
        </v-card-text>
        <v-card-actions class="text--center">
            <v-spacer></v-spacer>
            <v-btn v-fi="role != 'manager'" color="blue-grey darken-3" :loading="isLoading" dark class="mx-5" @click="Save()">Save</v-btn>
        </v-card-actions>
    </v-card>  
</template>

<script>
    import {bus} from '../../main'
    import {SERVER_API} from '../../API'
    import {store} from '../../store/index'
    export default {
        props:['id'],
        created(){
            window.addEventListener('keyup', event => {
                if(event.which === 27){
                    this.close();
                }
            })
        },
        data(){
            return {
                windowLoad:true,
                role: store.state.role,
                vNumber:'',
                dNumber:'',
                editorNot:'',
                rTime:'',
                isLoading:false,
            }
        },
        watch:{
            checkandfetch(){ 
                //
            }
        },
        computed:{
            checkandfetch(){
                return this.fetchbyId();
            },
        },
        methods:{
            close(){
               this.reset(); 
               bus.$emit('close',false);
            },
            reset(){
                this.id = '';
                this.vNumber = '';
                this.editorNot = '';
                this.dNumber = '';
                this.rTime = '';
            },
            async fetchbyId(){
                try{
                    this.windowLoad = true;
                    const Response = await this.$http.get(SERVER_API+'/bid/findbyid/'+this.id);
                    this.vNumber = Response.data.vNumber;
                    this.editorNot = Response.data.vNumber;
                    this.dNumber = Response.data.dNumber;
                    this.rTime = Response.data.rTime;
                    this.windowLoad = false;
                }catch(error){
                    this.windowLoad = false;
                    bus.$emit('error',error);
                }
            },
            Save(){
                this.create();
            },
            async create(){
                try{
                    this.isLoading = true;
                    const Response = await this.$http.post(SERVER_API+'/bid/truck/add',{
                        id:this.id,
                        vNumber:this.vNumber,
                        dNumber:this.dNumber,
                        rTime:this.rTime,
                    });
                    this.isLoading = false;
                    this.reset();
                    bus.$emit('response',{res: Response.data, type:'create', model:'truck'});
                    this.close();
                }catch(error){
                    this.isLoading = false;
                    bus.$emit('error',error);
                }
            },
        }
    }
</script>

<style>

</style>