<template>
  
    <v-img src="/images/grg_background.jpg" style="height:100vh">
    <v-row >
        <v-col md=5 sm=5 lg=4 cols=10 class="elevation-0 pa-0 ma-auto">
            <v-card min-height="100vh" color="transparent" flat>
                    <v-row class="pa-5" align="center" justify="center">
                        <v-row class="pa-5">
                            <v-col cols=12 class="text-center">
                                <v-avatar size="150" color="white" class="elevation-2">
                                    <img src="/images/Logo.png" alt="user" style="width:110px;height:100px;border-radius:unset">
                                </v-avatar>
                            </v-col>
                        </v-row>
                        <v-form @keyup.enter.native="login()">
                            <v-row class="pa-5 text-center" dense>
                                <v-col lg=8 cols=12 class="ma-auto py-0">
                                    <v-text-field v-model="email" label="Email" outlined prepend-inner-icon="mdi-account" rounded color="black" background-color="white"></v-text-field>
                                </v-col>
                                <v-col lg=8 cols=21 class="ma-auto py-0">
                                    <v-btn small color="black" block rounded ="" class="elevation-0 amber--text" @click="send()" :loading='isloading'>Submit</v-btn>
                                </v-col>
                                <v-col lg=8 cols=12 class="ma-auto mt-5">
                                    <router-link :to="{name:'login'}"><v-btn small color="black" text >Want to Login ?</v-btn></router-link>
                                </v-col>
                            </v-row>
                        </v-form>                  
                    </v-row>
            </v-card>
        </v-col>
    </v-row>
    <v-snackbar v-model="notificationBar" top center :color="notificationColor"> {{ notificationText }}
        <v-btn color="amber" text @click="notificationBar = false"> Close </v-btn>
    </v-snackbar>
        
  </v-img>
</template>

<script>
import { SERVER_API } from '../API'
export default {
    data(){
        return{
            email:'',
            isloading:false,
            // Notification Setting
            notificationColor: 'black',
            notificationBar: false,
            notificationText:''
        }
    },
    methods:{
        async send(){
            try{
                this.isloading =  true;
                const response = await this.$http.post(SERVER_API+'/forgot/email/password',{
                    email:this.email
                });
                if(response.data.action == true){
                    this.email='';
                    this.notificationBar = true;
                    this.notificationColor = 'black';
                    this.notificationText = response.data.message;
                    var v = this;
                    setTimeout(function () {
                        v.$router.push({name:'login'});
                    }, 5000);                    
                }else{
                    this.notificationBar = true;
                    this.notificationColor = 'red';
                    this.notificationText = response.data.message;
                }
                this.isloading =  false;
            }catch(error){
                this.isloading =  false;
                this.notificationBar = true;
                this.notificationColor = 'red';
                this.notificationText = error.response;
            }
        }

    }

}
</script>
