<template>
    <div>
        <v-menu offset-y v-if="role != 'admin'">
            <template v-slot:activator="{ on }">
                <v-badge :color="unReadCount > 0 ? 'red' : 'grey lighten-1'" overlap>
                    <template v-slot:badge>
                        <span>{{unReadCount}}</span>
                    </template>
                    <v-btn class="mx-2 elevation-0" fab dark small color="black" v-on="on">
                        <v-icon dark color="amber">mdi-bell</v-icon>
                    </v-btn>
                </v-badge>
            </template>
            <v-list two-line width="400" v-if="role == 'admin' || role == 'manager'" class="myscroller">
                <v-list-item-group multiple v-if="unReadCount > 0">
                    <template v-for="(notify, i) in unRead">
                        <!--<a @click="Read(notify, `/orders/${notify.data.productId}`)" :key="i">-->
                            <v-list-item :key="i">
                                <v-list-item-content>
                                    <v-list-item-title>{{notify.data.Product}}</v-list-item-title>
                                    <v-list-item-subtitle class="blue--text text--darken-4">{{notify.data.message}}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="notify.data.notificationType != 'approveOrder'"><span class="mr-3">From: {{notify.data.fromLocation}}</span>
                                        <span class="ml-3">To.: <span class="text-uppercase">{{notify.data.toLocation}}</span></span>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="notify.data.notificationType != 'approveOrder'"><span class="mr-3">Offer Rate: ₹ {{notify.data.update.rate}}/Qtl.-</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-list-item-action-text>15 min</v-list-item-action-text>
                                    <v-icon color="green lighten-1" x-small>mdi-checkbox-blank-circle</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider v-if="i + 1 < unRead.length" :key="notify.id"></v-divider>
                        <!--</a>-->
                    </template>
                </v-list-item-group>
                <v-list-item-group multiple v-else>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle class="text-center">Notifications is empty...</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
                            
            <v-list two-line width="400" v-if="role == 'transporter'" class="myscroller">
                <v-list-item-group multiple  v-if="unReadCount > 0">
                    <template v-for="(notify, i) in unRead">
                        <!--<a @click="Read(notify, `/customer/orders/${notify.data.update.product_data.id}`)" :key="i">-->
                        <v-list-item :key="i" @click="Read(notify)">
                            <v-list-item-content v-if="notify.data.notificationType == 'finalOrder'">
                                <v-list-item-title>{{notify.data.Product}}</v-list-item-title>
                                <v-list-item-subtitle class="blue--text text--darken-4">{{notify.data.message}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content v-else>
                                <v-list-item-title>{{notify.data.Product}}</v-list-item-title>
                                <!--<v-list-item-subtitle class="text--primary">You Got A Notification</v-list-item-subtitle>-->
                                <v-list-item-subtitle class="blue--text text--darken-4">{{notify.data.message}}</v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <span style="line-height:26px;" class="mr-3">From: <span class="text-uppercase">{{notify.data.fromLocation}}</span></span>
                                    <span style="line-height:26px;" class="ml-3">To: <span class="text-uppercase">{{notify.data.toLocation}}</span></span>
                                    <br>
                                    <span>Qty: <span class="text-uppercase">{{notify.data.update.qty}}/Tons</span></span>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon color="green lighten-1" x-small>mdi-checkbox-blank-circle</v-icon>
                                <!--<v-icon v-else color="yellow"> star </v-icon>-->
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider v-if="i + 1 < unRead.length" :key="notify.id"></v-divider>
                        <!--</a>-->
                    </template>
                </v-list-item-group>
                <v-list-item-group multiple v-else>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle class="text-center">Notifications is empty...</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>        
    </div>
</template>

<script>
import { SERVER_API } from '../../API'
import {bus} from '../../main';
import {store} from '../../store'
export default {
    data(){
        return{
            role:store.state.role,
            read:{},
            unRead:{},
            unReadCount:0,
            sound: "",
            notifyData:'',
        }
    },
    mounted(){
        this.getNotification();
    },
    created(){
        bus.$on('notify',() =>{
            this.getNotification();
        })
    },
    methods:{
        async getNotification(){
            const response = await this.$http.post(SERVER_API+'/notifications');
            this.read = response.data.read;
            this.unRead = response.data.unRead;
            this.unReadCount = response.data.unRead.length;
        },
        async Read(notification, data = false){
            await this.$http.post(SERVER_API+'/notification/markasread',{
                id: notification.id,
            });
            this.unRead.splice(notification,1);
            this.read.push(notification)
            this.unReadCount = this.unRead.length;
            if(data != false){
                location.href = data;
            }
        }
    }
}
</script>
<style>
    .vue-notification-wrapper{
        background: #dfdfdf !important;
        border-left:5px solid #aaaaaa !important;
        color:#000 !important;
     }
     .notification-title{
         font-size: 17px;
         padding-bottom: 10px;
     }
     .notification-content{
         font-size: 12px;
         line-height: 16px;
     }
     .myscroller{
         max-height: 450px !important;
         overflow-y: scroll !important;
         background: #fff !important;
     }
</style>