<template>
    <v-container fluid>
        <v-row>
            <v-col lg=4 sm=4 md=4>
                <v-chip label color="blue-grey darken-3" dark>
                    <v-icon left small>mdi-check-all</v-icon>Approved Bids
                </v-chip>
            </v-col>
            <v-col lg=8 sm=12 md=8 cols=12>
                <v-toolbar dense class="elevation-0" right height="35">
                    <v-spacer></v-spacer>
                    <router-link :to="{name:'dashboard'}"><v-btn color="primary" small class="elevation-0 mx-1" >New Bids</v-btn></router-link>
                    <router-link :to="{name:'pending'}"><v-btn color="primary" small class="elevation-0 mx-1" >Pending Bids</v-btn></router-link>
                    <router-link :to="{name:'approved'}"><v-btn color="primary" small class="elevation-0 mx-1" >Approved Bids</v-btn></router-link>
                     <router-link :to="{name:'allbids'}"><v-btn color="primary" small class="elevation-0 mx-1" >All Bids</v-btn></router-link>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="pageLoad == true">
                <v-card>
                    <v-skeleton-loader
                        type="article"
                    ></v-skeleton-loader>
                </v-card>
            </v-col>
            <v-col v-else cols=12>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col cols=12 sm=6 md=8 class="pa-0 ma-0"></v-col>
                            <v-col cols=12 sm=6 md=4 class="py-0 my-0">
                                <v-text-field  ref="name" color="blue-grey darken-3" v-model="search" append-icon="mdi-magnify" label="Search" outlined hide-details dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-data-table :headers="header"  :items="records" item-key="id" :search="search" hide-default-footer>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip color="green" dark small class="text-capitalize font-weight-bold" style="font-size:11px;">{{item.status}}</v-chip>
                        </template>
                        <template v-slot:[`item.detail`]="{ item }">
                            <v-btn color="green" class="elevation-0" x-small dark fab @click="selectedId = item.id;truckBox=true"><v-icon>mdi-eye</v-icon></v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <Paginate :links="links" :meta="meta" @paginate="fetchrecordsbypage" />
            </v-col>
            <v-dialog v-model="truckBox" persistent max-width="500px" transition="dialog-transition">
                <TruckDetails :id="selectedId"></TruckDetails>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import { SERVER_API } from '../API';
import { bus } from '../main';
import Config from '../config'
import TruckDetails from '../components/truck/index.vue'
import Paginate from '../components/pagination.vue'

export default {
    components:{TruckDetails, Paginate},
    created(){
        bus.$on('notify',() => {
          this.fetchrecords();
      });

        bus.$on('close',() =>  {
            this.truckBox = false;
        });

        bus.$on('error', error =>  {
            //alert('s');
            this.notificationBar = true;
            this.notificationColor = 'red';
            this.notificationText = Config.httpError(error);
        });

        bus.$on('response', data =>  {
            this.notificationBar = true;
            this.notificationColor = data.res.action == true ? 'black' : 'red';
            this.notificationText = data.res.message;
            this.truckBox = false;
        }); 
    },
    mounted(){
        this.fetchrecords();
    },
    data(){
        return{
            meta:[],
            links:[],
            pageLoad:true,
            truckBox:false,
            records:[],
            search:'',
            selectedId:'',
            header:[           
                {text:'Bid Id',value:'id'},  
                {text:'Bid By',value:'orderBy'},    
                {text:'Bid Date',value:'createdAt'},
                {text:'Product',value:'product'},
                {text:'From',value:'fromLocation'},
                {text:'To',value:'toLocation'},
                {text:'Qty / Tons',value:'qty'},
                {text:'Rate',value:'rate'},      
                {text:'Rmark',value:'tremark'},          
                {text:'Final Rate',value:'fRate'},
                {text:'Final Date',value:'updatedAt'},
                {text:'Approved Date',value:'approvedAt'},
                {text:'status',value:'status'},
                {text:'Vehicle Detail',value:'detail'},
            ],
        }
    },
    methods:{
        async fetchrecordsbypage(link){ 
            this.pageLoad = true;
            const response = await this.$http.get(SERVER_API+'/bid/approved/records?page='+link);
            this.records = response.data.data;
            this.meta =  response.data.meta;
            this.links =  response.data.links;
            this.pageLoad = false;
        },
        async fetchrecords(){
            const response = await this.$http.get(SERVER_API+'/bid/approved/records');
            this.records = response.data.data;
            this.meta =  response.data.meta;
            this.links =  response.data.links;
            this.pageLoad = false;
        },
    }

}
</script>