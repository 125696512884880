<template>
    <v-container fluid>
        <v-row>
            <v-col lg=4 sm=4 md=4>
                <v-chip label color="blue-grey darken-3" dark>
                    <v-icon left small>mdi-view-dashboard</v-icon>Dashboard
                </v-chip>
            </v-col>
            <v-col lg=8 sm=12 md=8 cols=12>
                <v-toolbar dense class="elevation-0" right height="35">
                    <v-spacer></v-spacer>
                    <router-link :to="{name:'tdashboard'}"><v-btn color="primary" small class="elevation-0 mx-1" >New Bids</v-btn></router-link>
                    <router-link :to="{name:'tpending'}"><v-btn color="primary" small class="elevation-0 mx-1" >Pending Bids</v-btn></router-link>
                    <router-link :to="{name:'tapproved'}"><v-btn color="primary" small class="elevation-0 mx-1" >Approved Bids</v-btn></router-link>
                    
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="pageLoad == true">
                <v-card>
                    <v-skeleton-loader
                        type="article"
                    ></v-skeleton-loader>
                </v-card>
            </v-col>
            <v-col v-else cols=12>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col cols=12 sm=6 md=8 class="pa-0 ma-0"></v-col>
                            <v-col cols=12 sm=6 md=4 class="py-0 my-0">
                                <v-text-field  ref="name" color="blue-grey darken-3" v-model="search" append-icon="mdi-magnify" label="Search" outlined hide-details dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-data-table :headers="header"  :items="records" item-key="id" :search="search" hide-default-footer>
                        <template v-slot:[`item.rate`]="{ item }">
                            <v-text-field label="Rate / Tons" v-model="rate[getIndex(records,item.id)]" outlined dense color="primary" hide-details></v-text-field>
                        </template>
                        <template v-slot:[`item.tremark`]="{ item }">
                            <v-text-field label="Enter Remark" v-model="tremark[getIndex(records,item.id)]" outlined dense color="primary" hide-details></v-text-field>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn color="primary" fab small @click="updateId=item.id; save();" :loading="item.id == updateId ? true : false" dark text><v-icon>mdi-send</v-icon></v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <Paginate :links="links" :meta="meta" @paginate="fetchrecordsbypage" />
            </v-col>
        </v-row>
        <!-- Notifications -->
        <v-snackbar v-model="notificationBar" :timeout="10000" top center :color="notificationColor" multi-line class="mt-70"> {{ notificationText }} <v-btn color="white" text fab @click="notificationBar = false"><v-icon>mdi-close</v-icon> </v-btn></v-snackbar>
    </v-container>
</template>

<script>
import { SERVER_API } from '../../API';
import { bus } from '../../main';
import Config from '../../config'
import Paginate from '../../components/pagination.vue'

export default {
    components:{
        Paginate
    },
    created(){
        bus.$on('notify',() => {
          this.fetchrecords();
      });
    },
    mounted(){
        this.fetchrecords();
    },
    data(){
        return{
            meta:[],
            links:[],
            pageLoad:true,
            records:[],
            search:'',
            rate:[],
            tremark:[],
            updateId:'',
            header:[
                {text:'Date/Time',value:'createdAt'},
                {text:'Product',value:'product'},
                {text:'from',value:'fromLocation'},
                {text:'to',value:'toLocation'},
                {text:'Qty / Tons',value:'qty'},
                {text:'Remark',value:'uremark'},
                {text:'Rate',value:'rate'},
                {text:'My Remark',value:'tremark'},
                {text:'Action',value:'action'},
            ],
            //Notifications
            notificationBar:false,
            notificationColor:'black',
            notificationText:''
        }
    },
    methods:{
        getIndex(list, id) {
            return list.findIndex((e) => e.id == id)
        },

        async fetchrecordsbypage(link){ 
            this.pageLoad = true;
            const response = await this.$http.get(SERVER_API+'/dailybid/byuser/records?page='+link);
            this.records = response.data.data;
            this.meta =  response.data.meta;
            this.links =  response.data.links;
            this.pageLoad = false;
        },
        async fetchrecords(){
            const response = await this.$http.get(SERVER_API+'/dailybid/byuser/records');
            this.records = response.data.data;
            this.pageLoad = false;
        },
        async save(){
            try{
                const response = await this.$http.post(SERVER_API+'/bid/create',{
                    updateId:this.updateId,
                    rate:this.rate[this.getIndex(this.records, this.updateId)],
                    tremark:this.tremark[this.getIndex(this.records, this.updateId)]
                });
                this.notificationBar = true;
                this.notificationColor = response.data.action == true ? 'black' : 'red';
                this.notificationText = response.data.message;
                this.submitBid();
                this.updateId = '';
            }catch(error){
                this.updateId = '';
                this.notificationBar = true;
                this.notificationColor = 'red';
                this.notificationText = Config.httpError(error);
            }
        },
        submitBid(){
            this.rate.splice(this.getIndex(this.records, this.updateId),1);
            this.records.splice(this.getIndex(this.records, this.updateId),1);
            this.updateId = '';
        },
    }

}
</script>