import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import Route from './router';
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuex from 'vuex'
import {PUSHER_API,PUSHER_KEY} from './API'
import { store } from './store'
import Notifications from 'vue-notification'
import Config from './config'


Vue.use(require('vue-pusher'), {
  api_key: PUSHER_KEY,
  options: {
      cluster: 'ap2',
      encrypted: true,
      authEndpoint: PUSHER_API,
      auth:{  
        headers:{
            Authorization : 'Bearer '+store.state.token,
        }
      }
  },
});

Vue.use(Notifications)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false;
Vue.prototype.$config = Config;

const router = new VueRouter({
  routes: Route,
  mode: 'history'
})

axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: 'login',
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({
        name: 'dashboard',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export const bus  = new Vue();

new Vue({
  store: store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
