<template>
    <v-container class="pa-0 mt-5" width="100%">
        <v-row class="mx-0">
            <v-col cols=6 md=6 lg=6 sm=6>
                 <v-chip label color="primary"> <v-icon left>mdi-wrench</v-icon>My Settings</v-chip>
            </v-col>
        </v-row>
        <v-card v-if="pageLoad == true" color="white" width="100%" flat>
            <v-skeleton-loader type="article"></v-skeleton-loader>
            <v-skeleton-loader type="article"></v-skeleton-loader>
            <v-skeleton-loader type="actions"></v-skeleton-loader>
        </v-card>
        <v-card v-else color="white" width="100%" flat>
            <v-form class="pa-5">
                <v-row dense>
                    <v-col md=6 sm=6 cols=12 class="py-0">
                        <v-text-field v-model="name" label="Name" outlined color="primary"></v-text-field>
                    </v-col>
                    <v-col md=6 sm=6 cols=12 class="py-0">
                        <v-text-field type="email" v-model="email" label="Email" outlined color="primary"></v-text-field>
                    </v-col>
                    <v-col md=6 sm=6 cols=12 class="py-0">
                        <v-text-field v-model="phone" label="Phone" outlined color="primary"></v-text-field>
                    </v-col>
                    <v-col md=6 sm=6 cols=12 class="py-0">
                        <v-text-field disabled readonly v-model="username" label="Username" outlined color="primary"></v-text-field>
                    </v-col>
                    <v-col md=12 sm=12 cols=12 class="py-0">
                        <v-text-field v-model="password" label="Password" outlined color="primary"></v-text-field>
                    </v-col>
                    <v-col cols=12 md=12 sm=12 lg=12>
                    <v-btn color="primary" dark @click="save()">Update</v-btn>
                    </v-col>
                </v-row>
            </v-form> 
        </v-card>
        <v-snackbar v-model="notificationBar" top center :color="notificationColor" multi-line> {{ notificationText }}
            <v-btn color="primary" text @click="notificationBar = false"> Close </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import { SERVER_API } from "../API/";
export default {
    mounted(){
        this.fetchById();
    },
    data(){
        return{
            pageLoad:true,
            name:'',
            email:'',
            phone:'',
            password:'',
            username:'',
            userData:'',

            // Notification Setting
            notificationColor: 'black',
            notificationBar: false,
            notificationText:'',
        }
    },
    methods:{
        async fetchById(){
                const response = await this.$http.get(SERVER_API+'/user/info');
                this.name = response.data.name;
                this.email = response.data.email;
                this.phone = response.data.phone;
                this.username = response.data.username;
                this.pageLoad = false;
        },
        async save(){
                try{
                    const response = await this.$http.put(SERVER_API+'/user/setting/update',{
                        name: this.name,
                        email: this.email,
                        phone: this.phone,
                        password: this.password
                    });
                    this.password ='';
                    this.notificationColor = 'black';
                    this.notificationBar = true;
                    this.notificationText = response.data.message;
                }catch(error){
                    this.notificationColor = 'red';
                    this.notificationBar = true;
                    this.notificationText = error.message;
                }
        }
    }

}
</script>

<style>

</style>