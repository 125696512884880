<template>
    <v-container fluid>
        <v-row>
            <v-col lg=4 sm=4 md=4>
                <v-chip label color="blue-grey darken-3" dark>
                    <v-icon left small>mdi-view-headline</v-icon>All Bids
                </v-chip>
            </v-col>
            <v-col lg=8 sm=12 md=8 cols=12>
                <v-toolbar dense class="elevation-0" right height="35">
                    <v-spacer></v-spacer>
                    <router-link :to="{name:'dashboard'}"><v-btn color="primary" small class="elevation-0 mx-1" >New Bids</v-btn></router-link>
                    <router-link :to="{name:'pending'}"><v-btn color="primary" small class="elevation-0 mx-1" >Pending Bids</v-btn></router-link>
                    <router-link :to="{name:'approved'}"><v-btn color="primary" small class="elevation-0 mx-1" >Approved Bids</v-btn></router-link>
                    <router-link :to="{name:'allbids'}"><v-btn color="primary" small class="elevation-0 mx-1" >All Bids</v-btn></router-link>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="pageLoad == true">
                <v-card>
                    <v-skeleton-loader
                        type="article"
                    ></v-skeleton-loader>
                </v-card>
            </v-col>
            <v-col v-else cols=12>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col cols=12 sm=6 md=8 class="pa-0 ma-0"></v-col>
                            <v-col cols=12 sm=6 md=4 class="py-0 my-0">
                                <v-text-field  ref="name" color="blue-grey darken-3" v-model="search" append-icon="mdi-magnify" label="Search" outlined hide-details dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-data-table v-model="selection" :headers="header" :items="records" item-key="id" :search="search" hide-default-footer>
                        <template v-slot:[`item.open`]="{ item }">
                            <v-avatar size="22" color="green">
                                <span class="white--text caption">{{item.bidCount}} </span>
                            </v-avatar>
                            
                            <v-btn color="blue-grey darken-3" fab small class="mx-3"
                            @click="updateID=item.id; editBox=true;" dark text>
                            <v-icon>mdi-open-in-new</v-icon></v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <Paginate :links="links" :meta="meta" @paginate="fetchrecordsbypage" />
            </v-col>
        </v-row>
        <v-dialog v-model="editBox" scrollable persistent max-width="1200px" transition="dialog-transition">
            <pending :id='updateID' ></pending>
        </v-dialog>
    </v-container>
</template>

<script>
import { SERVER_API } from '../API';
import {bus} from '../main'
import pending from '../components/pending/index.vue'
import Config from '../config'
import Paginate from '../components/pagination.vue'

export default {
    components:{pending,Paginate},
    created(){
        bus.$on('close',() =>  {
            this.createBox = false;
            this.editBox = false;
            this.deleteBox = false;
            this.selection = [];
        });

        bus.$on('error', error =>  {
            //alert('s');
            this.notificationBar = true;
            this.notificationColor = 'red';
            this.notificationText = Config.httpError(error);
        });

        bus.$on('notify',() => {
          this.fetchrecords();
      })
      
    },
    mounted(){
        this.fetchrecords();
    },
    data(){
        return{
            meta:[],
            links:[],
            pageLoad:true,
            records:[],
            header:[
                {text:'Bid Id',value:'id'},
                {text:'Date/Time',value:'createdAt'},
                {text:'Product',value:'product'},
                {text:'From',value:'fromLocation'},
                {text:'To',value:'toLocation'},
                {text:'Qty / Tons',value:'qty'},
                {text:'Remark',value:'uremark'},
                {text:'Open',value:'open'},
            ],
            editBox:false,
            updateID:'',
        }
    },
    methods:{
        async fetchrecordsbypage(link){ 
            this.pageLoad = true;
            const response = await this.$http.get(SERVER_API+'/dailybid/records?page='+link);
            this.records = response.data.data;
            this.meta =  response.data.meta;
            this.links =  response.data.links;
            this.pageLoad = false;
        },
        async fetchrecords(){
            const response = await this.$http.get(SERVER_API+'/dailybid/records');
            this.records = response.data.data;
            this.meta =  response.data.meta;
            this.links =  response.data.links;
            this.pageLoad = false;
        }
    }

}
</script>