<template>
    <v-card>
        <v-app-bar  dark color="blue-grey darken-3" dense flat>
            <v-toolbar-title>New Update</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" text fab x-small @click="close()"><v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>
        <v-card-text class="pt-5">
            <v-row>
                <v-col md=4>
                    <v-autocomplete label="From Location" :items="locationHolder"
                    v-model="fromLocation" color="primary" outlined dense hide-details item-value="id" item-text="name"></v-autocomplete>
                </v-col>
                <v-col md=4>
                    <v-autocomplete label="To Location" :items="locationHolder"
                    v-model="toLocation" color="primary" outlined dense hide-details item-value="id" item-text="name"></v-autocomplete>
                </v-col>
                <v-col md=4>
                    <v-text-field v-model="uremark" label="Remark" outlined color="primary" dense hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col md=4>
                    <v-autocomplete label="Product" :items="productHolder"
                    v-model="product" color="primary" outlined dense hide-details item-value="id" item-text="name"></v-autocomplete>
                </v-col>
                <v-col md=4>
                    <v-text-field type="number" v-model="qty" label="Qty / Tons" outlined color="primary" dense hide-details></v-text-field>
                </v-col>
                <v-col md=4>
                    <v-autocomplete label="Vehicles Type" :items="vehiclesHolder"
                    v-model="transport" color="primary" outlined dense hide-details item-value="id" item-text="name"></v-autocomplete>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="text--center">
            <v-spacer></v-spacer>
            <v-btn color="blue-grey darken-3" :loading="isLoading" dark class="mx-5" @click="Save()">Submit</v-btn>
        </v-card-actions>
    </v-card>  
</template>

<script>
    import {bus} from '../../main'
    import {SERVER_API} from '../../API'
    export default {
        watch:{
            fromLocation(val){
                if(val !='' && val== this.toLocation){
                    this.toLocation = '';
                    alert('Both Location can not be same');                    
                }
            },
            toLocation(val){
                if(val !='' && val == this.fromLocation){
                    this.fromLocation = '';                    
                    alert('Both Location can not be same');                    
                }
            }

        },
        mounted(){
            this.fetchProduct();
            this.fetchLocation();
            this.fetchVehicles();
        },
        data(){
            return {
                fromLocation:'',
                toLocation:'',
                uremark:'',
                product:'',
                transport:'',
                qty:'',
                isLoading:false,
                locationHolder:[],
                productHolder:[],
                vehiclesHolder:[]
            }
        },
        created(){
            window.addEventListener('keyup', event => {
                if(event.which === 27){
                    this.close();
                }
                if(event.which === 13){
                    this.Save();
                }
            })
        },
        methods:{
            close(){
               this.edit == true ? '' : this.reset(); 
               bus.$emit('close',false);
            },
            reset(){
                this.fromLocation = '';
                this.toLocation = '';
                this.product = '';
                this.transport =''
                this.qty = '';
                this.uremark = '';
            },
            async fetchLocation(){
                const response = await this.$http.get(SERVER_API+'/location/records');
                this.locationHolder = response.data;
            },
            async fetchProduct(){
                const response = await this.$http.get(SERVER_API+'/product/records');
                this.productHolder = response.data;
            },
            async fetchVehicles(){
                const response = await this.$http.get(SERVER_API+'/vehicle/records');
                this.vehiclesHolder = response.data;
            },
            async Save(){
                try{
                    this.isLoading = true;
                    const Response = await this.$http.post(SERVER_API+'/dailybid/create',{
                        fromLocation:this.fromLocation,
                        toLocation:this.toLocation,
                        product:this.product,
                        transport: this.transport,
                        qty:this.qty,
                        uremark:this.uremark
                    });
                    this.isLoading = false;
                    this.reset();
                    bus.$emit('response',{res: Response.data, type:'bid', model:'dailybid'});
                    this.close();
                }catch(error){
                    this.isLoading = false;
                    bus.$emit('error',error);
                }
            },
        }
    }
</script>

<style>

</style>