<template>
    <v-container fluid>
        <v-row>
            <v-col lg=4 sm=4 md=4>
                <v-chip label color="blue-grey darken-3" dark>
                    <v-icon left small>mdi-view-dashboard</v-icon>Pending
                </v-chip>
            </v-col>
            <v-col lg=8 sm=12 md=8 cols=12>
                <v-toolbar dense class="elevation-0" right height="35">
                    <v-spacer></v-spacer>
                    <router-link :to="{name:'dashboard'}"><v-btn color="primary" small class="elevation-0 mx-1" >New Bids</v-btn></router-link>
                    <router-link :to="{name:'pending'}"><v-btn color="primary" small class="elevation-0 mx-1" >Pending Bids</v-btn></router-link>
                    <router-link :to="{name:'approved'}"><v-btn color="primary" small class="elevation-0 mx-1" >Approved Bids</v-btn></router-link>
                     <router-link :to="{name:'allbids'}"><v-btn color="primary" small class="elevation-0 mx-1" >All Bids</v-btn></router-link>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="pageLoad == true">
                <v-card>
                    <v-skeleton-loader
                        type="article"
                    ></v-skeleton-loader>
                </v-card>
            </v-col>
            <v-col v-else cols=12>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col cols=12 sm=6 md=8 class="pa-0 ma-0">
                                <v-btn v-if="selection.length > 0" color="red" small class=" mx-5 elevation-0" @click="deleteBox=true" dark>Delete</v-btn>
                            </v-col>
                            <v-col cols=12 sm=6 md=4 class="py-0 my-0">
                                <v-text-field  ref="name" color="blue-grey darken-3" v-model="search" append-icon="mdi-magnify" label="Search" outlined hide-details dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-data-table v-model="selection" :headers="header"  :items="records" :show-select="role == 'admin' ? true : false"
                    item-key="id" :search="search" hide-default-footer>
                        <template v-slot:[`item.open`]="{ item }">
                            <v-avatar size="22" color="green">
                                <span class="white--text caption">{{item.bidCount}} </span>
                            </v-avatar>
                            
                            <v-btn color="blue-grey darken-3" fab small class="mx-3"
                            @click="updateID=item.id; editBox=true;" dark text>
                            <v-icon>mdi-open-in-new</v-icon></v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <Paginate :links="links" :meta="meta" @paginate="fetchrecordsbypage" />
            </v-col>
        </v-row>
        <v-dialog v-model="editBox" scrollable persistent max-width="1200px" transition="dialog-transition">
            <pending :id='updateID' ></pending>
        </v-dialog>
        <v-dialog v-model="deleteBox" scrollable persistent max-width="500px" transition="dialog-transition">
            <delConfirm :url="delUrl" :selectedId="deleteconveter" ></delConfirm>
        </v-dialog>
    </v-container>
</template>

<script>
import { SERVER_API } from '../API';
import {bus} from '../main'
import {store} from '../store'
import delConfirm from '../components/confirmation/index.vue'
import pending from '../components/pending/index.vue'
import Config from '../config'
import Paginate from '../components/pagination.vue'

export default {
    components:{delConfirm,pending,Paginate},
    mounted(){
        this.fetchrecords();
    },
    data(){
        return{
            meta:[],
            links:[],
            pageLoad:true,
            role: store.state.role,
            delUrl:SERVER_API+'/dailybid/delete',
            records:[],
            search:'',
            header:[
                {text:'Update Id',value:'id'},
                {text:'Date/Time',value:'createdAt'},
                {text:'Product',value:'product'},
                {text:'From',value:'fromLocation'},
                {text:'To',value:'toLocation'},
                {text:'Qty / Tons',value:'qty'},
                {text:'Remark',value:'uremark'},
                {text:'Open',value:'open'},
            ],
            deleteBox:false,
            editBox:false,
            updateID:'',
            // Selection of Data
            selection:[],
        }
    },
    created(){
        bus.$on('notify',() => {
          this.fetchrecords();
        })
        window.addEventListener('keyup', event => {
            if(this.selection.length > 0){
                if(event.which === 46){
                    this.deleteBox = true;
                }
            }
        });
        bus.$on('close',() =>  {
            this.editBox = false;
            this.deleteBox = false;
            this.selection = [];
        });

        bus.$on('error', error =>  {
            //alert('s');
            this.notificationBar = true;
            this.notificationColor = 'red';
            this.notificationText = Config.httpError(error);
        });

        bus.$on('response', data =>  {
            if(data.res.action == true){
            this.notificationBar = true;
            this.notificationColor = 'black';
            this.notificationText = data.res.message;
            }else{
                this.notificationBar = true;
                this.notificationColor = 'red';
                this.notificationText = data.res.message;

            }
            
            this.fetchrecords();
        });

        bus.$on('deleted', () =>  {
            for(let i = 0; i < this.selection.length; i++){
                if(this.records.indexOf(this.selection[i])!= -1){
                    this.records.splice(this.records.indexOf(this.selection[i]),1);
                }
            }
        });            
    },
    computed:{
        deleteconveter(){
            var deleteId = [...new Set(this.selection.map(it => it.id))];
            return deleteId.toString();
        }
    },
    methods:{
        async fetchrecordsbypage(link){ 
            this.pageLoad = true;
            const response = await this.$http.get(SERVER_API+'/dailybid/pending/records?page='+link);
            this.records = response.data.data;
            this.meta =  response.data.meta;
            this.links =  response.data.links;
            this.pageLoad = false;
        },
        async fetchrecords(){
            const response = await this.$http.get(SERVER_API+'/dailybid/pending/records');
            this.records = response.data.data;
            this.meta =  response.data.meta;
            this.links =  response.data.links;
            this.pageLoad = false;
        }
    }

}
</script>