<template>
  <p class="font-weight-bold grey--text text--darken-5 text--center dispay-1">Have a Good Day...</p>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('destroyToken')
      .then(() => {
        this.$router.push({ name: 'dashboard' })
      })
  }
}
</script>