<template>
    <v-col v-if="windowLoad == true">
        <v-card>
            <v-skeleton-loader
                type="article"
            ></v-skeleton-loader>
        </v-card>
    </v-col>
    <v-col v-else cols=12 class="pa-0">
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols=12 sm=6 md=9 class="pa-0 ma-0">
                        <v-toolbar color="white" height="40" class="elevation-0">
                            <h4 class="subtitle-1">Update Id {{id}}</h4>
                            <v-spacer></v-spacer>
                            <v-btn color="red lighten-4" fab x-small class="mx-2 elevation-0" @click="close()"><v-icon color="red">mdi-close</v-icon></v-btn>
                            <v-spacer></v-spacer>                            
                        </v-toolbar>
                    </v-col>
                    <v-col cols=12 sm=6 md=3 class="py-0 my-0">
                        <v-text-field  ref="name" color="blue-grey darken-3" v-model="search" append-icon="mdi-magnify" label="Search" outlined hide-details dense></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-data-table :headers="header"  :items="records" item-key="id" :search="search">
                <template v-slot:[`item.fRate`]="{ item }">
                    <span v-if="item.fRate == null || role == 'admin'">
                        <v-text-field label="Final Rate / Tons" v-model="fRate[getIndex(records,item.id)]" outlined dense color="primary" hide-details></v-text-field>
                    </span>
                    <span v-else>
                        {{item.fRate}}
                    </span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <span v-if="item.fRate == null || role == 'admin'">
                        <v-avatar size="22" color="green" class="mx-3" v-if="item.status == 'approved'" title="Approved">
                            <v-icon color="white" small>mdi-check</v-icon>
                        </v-avatar>
                        <v-avatar size="22" color="orange" class="mx-3" v-if="item.status == 'pending'" title="Pending">
                            <v-icon color="white" small>mdi-information-variant</v-icon>
                        </v-avatar>
                        <v-btn color="primary" small @click="selectedId=item.id; save();" :loading="selectedId == item.id ? true  : false" dark>Submit</v-btn>
                    </span>
                    <span v-else>
                        <v-chip small color="green" v-if="item.status == 'approved'" dark>Approved</v-chip>
                        <v-chip small color="orange" v-if="item.status == 'pending'">Pending</v-chip>
                    </span>
                </template>
            </v-data-table>
        </v-card>
    </v-col>
</template>

<script>
import { SERVER_API } from '../../API';
import {store} from '../../store'
import {bus} from '../../main'
//import Config from '../config'

export default {
    created(){
        window.addEventListener('keyup', event => {
            if(event.which === 27){
                this.close();
            }
        })
    },
    props:['id'],
    data(){
        return{
            windowLoad:true,
            role: store.state.role,
            tmbox:false,
            records:[],
            fRate:[],
            search:'',
            selectedId:'',
            header:[           
                {text:'Bid Id',value:'id'},  
                {text:'Bid By',value:'orderBy'},    
                {text:'Bid Date',value:'createdAt'},
                {text:'Rate',value:'rate'},   
                {text:'Remark',value:'tremark'},                
                {text:'Final Rate',value:'fRate'},
                {text:'Action',value:'action'},
            ],
        }
    },
    watch:{
        statusHere(val){
            if(val == true){
                this.status = 1;
            }else{
                this.status = 0;
            }
        },
        checkandfetch(){ 
            //
        },
    },
    computed:{
        checkandfetch(){
            return this.fetchrecords();
        },
    },
    methods:{
        close(){
            this.id = '';
            this.edit == true ? '' : this.reset(); 
            bus.$emit('close',false);
        },
        reset(){
            this.fRate = [];
        },
        getIndex(list, id) {
            return list.findIndex((e) => e.id == id)
        },
        async save(){
            try{
                const response = await this.$http.put(SERVER_API+'/bid/final',{
                    fRate:this.fRate[this.getIndex(this.records, this.selectedId)],
                    id:this.selectedId,
                    updateId:this.id
                });
                this.selectedId = '';
                        this.reset();
                        bus.$emit('response',{res: response.data, type:'update', model:'pendingBid'});
                        this.close();
            }catch(error){
                this.selectedId = '';
                bus.$emit('error',error);
            }
        },
        async fetchrecords(){
            this.windowLoad = true;
            const response = await this.$http.get(SERVER_API+'/bid/records/'+this.id);
            this.records = response.data.data;
            var data = [];
            for(var record in this.records){
                if (this.records.hasOwnProperty(record)) {
                    data.push(this.records[record].fRate);
                }
            }
            this.fRate = data;
            this.windowLoad = false;
        },
    }

}
</script>