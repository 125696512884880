<template>
    <v-card v-if="windowLoad == true">
        <v-skeleton-loader type="article"></v-skeleton-loader>
        <v-skeleton-loader type="article"></v-skeleton-loader>
        <v-skeleton-loader type="actions"></v-skeleton-loader>
    </v-card>
    <v-card v-else>
        <v-app-bar  dark color="blue-grey darken-3" dense flat>
            <v-toolbar-title>
                {{edit == true ? `Edit User` : `New User`}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" text fab x-small @click="close()"><v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>

        <v-card-text class="pt-5">
            <v-row>
                <v-col md=6>
                    <v-text-field v-model="name" label="Name" outlined color="blue-grey darken-3" dense hide-details></v-text-field>
                </v-col>
                <v-col md=6>
                    <v-text-field v-model="company" label="Company Name" outlined color="blue-grey darken-3" dense hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col md=6>
                    <v-text-field v-model="email" label="Email" outlined color="blue-grey darken-3" dense hide-details></v-text-field>
                </v-col>
                <v-col md=6>
                    <v-text-field v-model="phone" label="Phone" outlined color="blue-grey darken-3" dense hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col md=4>
                    <v-autocomplete v-model="role" :items="['analyser','admin','manager','transporter']" label="User Role" outlined color="blue-grey darken-3" dense hide-details></v-autocomplete>
                </v-col>
                <v-col md=4>
                    <v-text-field v-model="username" label="Username" outlined color="blue-grey darken-3" dense hide-details></v-text-field>
                </v-col>
                <v-col md=4>
                    <v-text-field v-model="password" label="Password" outlined color="blue-grey darken-3" dense hide-details></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="text--center">
            <v-spacer></v-spacer>
            <v-switch v-model="statusHere" :label="statusHere == true ? 'Active': 'In Active'" :value="true" color="primary"></v-switch>
            <v-btn color="blue-grey darken-3" :loading="isLoading" dark class="mx-5" @click="Save()">Save</v-btn>
        </v-card-actions>
    </v-card>  
</template>

<script>
    import {bus} from '../../main'
    import {SERVER_API} from '../../API'
    export default {
        props:['id','edit'],
        created(){
            window.addEventListener('keyup', event => {
                if(event.which === 27){
                    this.close();
                }
                if(event.which === 13){
                    this.Save();
                }
            })
        },
        data(){
            return {
                windowLoad:false,
                name:'',
                company:'',
                email:'',
                phone:'',
                role:'',
                username:'',
                password:'',
                status:1,
                statusHere:true,
                isLoading:false,
            }
        },
        watch:{
            statusHere(val){
                if(val == true){
                    this.status = 1;
                }else{
                    this.status = 0;
                }
            },
            checkandfetch(){ 
                //
            }
        },
        computed:{
            checkandfetch(){
                return this.fetchbyId();
            },
        },
        methods:{
            close(){
               this.edit == true ? '' : this.reset(); 
               bus.$emit('close',false);
            },
            reset(){
                this.name = '';
            },
            async fetchbyId(){
                if(this.edit == true){
                    //alert(this.id);
                    try{
                        this.windowLoad = true;
                        const Response = await this.$http.get(SERVER_API+'/user/findbyid/'+this.id);
                        this.name = Response.data.name;
                        this.company = Response.data.company;
                        this.email = Response.data.email;
                        this.phone = Response.data.phone;
                        this.role = Response.data.role;
                        this.username = Response.data.username;
                        this.status = Response.data.status;
                        this.statusHere = Response.data.status == 0 ? false : true
                        this.windowLoad = false;
                    }catch(error){
                        this.windowLoad = false;
                        bus.$emit('error',error);
                    }
                }
            },
            Save(){
                this.edit == true ? this.update() : this.create();
            },
            async create(){
                try{
                    this.isLoading = true;
                    const Response = await this.$http.post(SERVER_API+'/user/create',{
                        name: this.name,
                        company: this.company,
                        email: this.email,
                        phone: this.phone,
                        role: this.role,
                        username: this.username,
                        password: this.password,
                        status:this.status
                    });
                    this.isLoading = false;
                    this.reset();
                    bus.$emit('response',{res: Response.data, type:'create', model:'user'});
                    this.close();
                }catch(error){
                    this.isLoading = false;
                    bus.$emit('error',error);
                }
            },
            async update(){
                try{
                    this.isLoading = true;
                    const Response = await this.$http.put(SERVER_API+'/user/update',{
                        id:this.id,
                        name: this.name,
                        company: this.company,
                        email: this.email,
                        phone: this.phone,
                        role: this.role,
                        username: this.username,
                        password: this.password,
                        status:this.status
                    });
                    this.isLoading = false;
                    bus.$emit('response',{res: Response.data, type:'update', model:'user'});
                    this.close();
                }catch(error){
                    this.isLoading = false;
                    bus.$emit('error',error);
                }
            }
        }
    }
</script>

<style>

</style>