var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"lg":"6","sm":"6","md":"6"}},[_c('v-chip',{attrs:{"label":"","color":"blue-grey darken-3","dark":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-chart-line")]),_vm._v(" Report Manager ")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"height":"150"}},[_c('v-autocomplete',{attrs:{"label":"Select Product","outlined":"","items":_vm.products,"color":"primary","multiple":"","item-text":"name","item-value":"id","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.product.length - 1)+" others)")]):_vm._e()]}}]),model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}}),_c('v-spacer'),_c('v-menu',{ref:"fromdateMenu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Start","prepend-icon":"mdi-calendar-range","outlined":"","color":"primary","readonly":"","dense":"","hide-details":""},model:{value:(_vm.fromdate),callback:function ($$v) {_vm.fromdate=$$v},expression:"fromdate"}},on))]}}]),model:{value:(_vm.fromdateMenu),callback:function ($$v) {_vm.fromdateMenu=$$v},expression:"fromdateMenu"}},[_c('v-date-picker',{attrs:{"color":"primary","scrollable":"","max":new Date().toISOString().substr(0, 10)},model:{value:(_vm.fromdate),callback:function ($$v) {_vm.fromdate=$$v},expression:"fromdate"}})],1),_c('v-spacer'),_c('v-menu',{ref:"todateMenu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"prepend-icon":"mdi-calendar-range","outlined":"","label":"End","color":"primary","readonly":"","dense":"","hide-details":""},model:{value:(_vm.todate),callback:function ($$v) {_vm.todate=$$v},expression:"todate"}},on))]}}]),model:{value:(_vm.todateMenu),callback:function ($$v) {_vm.todateMenu=$$v},expression:"todateMenu"}},[_c('v-date-picker',{attrs:{"color":"primary","scrollable":"","max":new Date().toISOString().substr(0, 10)},model:{value:(_vm.todate),callback:function ($$v) {_vm.todate=$$v},expression:"todate"}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.isLoading},on:{"click":_vm.fetchall}},[_vm._v("Fetch Data")])],1)],1)],1),_c('v-row',[(_vm.records.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[(_vm.records.length > 0)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.exportexcelfile()}}},[_vm._v("download")]):_vm._e()],1),_c('v-data-table',{attrs:{"item-key":"id","loading":"true","hidden":""}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }