import { store } from '../store'
import Dashboard from '../views/dashboard.vue'
import tDashboard from '../views/transport/dashboard.vue'
// Independent with components
import Product from '../views/product.vue'
import Location from '../views/location.vue'
import User from '../views/user.vue'

// Login Logout
import Login from '../views/login.vue'
import Logout from '../views/logout.vue'
import Forgot from '../views/forgot.vue'
import Change from '../views/change.vue'
import Setting from '../views/setting.vue'
import Vehicle from '../views/vehicle.vue'
import tApprove from '../views/transport/approve.vue'
import tPending from '../views/transport/pending.vue'
import Approve from '../views/approved.vue'
import Pending from '../views/pending.vue'
import AllBids from '../views/allbid.vue'
import Report from '../views/report.vue'


export default [
    // Transport
    {path:'/transport', component:tDashboard, name:'tdashboard', 
    redirect: store.state.role == 'admin' || store.state.role == 'manager' || store.state.role == 'analyser' ? { name: 'dashboard' } : '', 
    meta: { requiresAuth: true} },
    {path:'/transport/pending', component:tPending, name:'tpending', 
    redirect: store.state.role == 'admin' || store.state.role == 'manager' || store.state.role == 'analyser' ? { name: 'pending' } : '', 
    meta: { requiresAuth: true} },
    {path:'/transport/approve', component:tApprove, name:'tapproved', 
    redirect: store.state.role == 'admin' || store.state.role == 'manager' || store.state.role == 'analyser' ? { name: 'approved' } : '', 
    meta: { requiresAuth: true} },
   
    //Admin and Manager
    {path:'/', component:Dashboard, name:'dashboard', 
    redirect: store.state.role == 'transporter' ? { name: 'tdashboard' } : '', 
    meta: { requiresAuth: true} },
    {path:'/report', component:Report, name:'report', 
    redirect: store.state.role == 'transporter' ? { name: 'tdashboard' } : '', 
    meta: { requiresAuth: true} },
    // Independent with  components
    {path:'/product', component:Product, name:'product', 
    redirect: store.state.role == 'transporter' ? { name: 'tdashboard' } : '', 
    meta: { requiresAuth: true} },
    {path:'/location', component:Location, name:'location', 
    redirect: store.state.role == 'transporter' ? { name: 'tdashboard' } : '', 
    meta: { requiresAuth: true} },
    {path:'/user', component:User, name:'user', 
    redirect: store.state.role == 'transporter' ? { name: 'tdashboard' } : '', 
    meta: { requiresAuth: true} },
    {path:'/setting', component:Setting, name:'setting', meta: { requiresAuth: true} },
    {path:'/vehicle', component:Vehicle, name:'vehicle', 
    redirect: store.state.role == 'transporter' ? { name: 'tdashboard' } : '', 
    meta: { requiresAuth: true} },

    {path:'/pending', component:Pending, name:'pending', 
    redirect: store.state.role == 'transporter' ? { name: 'tpending' } : '', 
    meta: { requiresAuth: true} },
    {path:'/approve', component:Approve, name:'approved', 
    redirect: store.state.role == 'transporter' ? { name: 'tapproved' } : '', 
    meta: { requiresAuth: true} },
    {path:'/allbids', component:AllBids, name:'allbids', 
    redirect: store.state.role == 'transporter' ? { name: 'tdashboard' } : '', 
    meta: { requiresAuth: true} },
    

    // Login And Logout
    {path: '/forgot/password', name: 'forgot', component: Forgot, meta: { requiresVisitor: true }},
    {path: '/update/password', name: 'updatepassword', component: Change, meta: { requiresVisitor: true }},
    {path:'/login', component:Login, name:'login', meta: { requiresVisitor: true }},
    {path:'/logout', component:Logout, name:'logout', meta: { requiresAuth: true} }

]