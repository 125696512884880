<template>
    <v-container fluid>
        <v-row>
            <v-col lg=6 sm=6 md=6>
                <v-chip label color="blue-grey darken-3" dark>
                    <v-icon left small>mdi-chart-line</v-icon> Report Manager
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols=12>
                <v-toolbar height=150>
                    <v-autocomplete label="Select Product" outlined :items="products" color="primary"
                    multiple item-text="name" item-value="id" v-model="product" dense hide-details>
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0"><span>{{ item.name }}</span></v-chip>
                            <span v-if="index === 1" class="grey--text caption">(+{{ product.length - 1 }} others)</span>
                        </template>                            
                    </v-autocomplete>
                    <v-spacer></v-spacer>
                    <v-menu ref="fromdateMenu" v-model="fromdateMenu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field label="Start" prepend-icon="mdi-calendar-range" outlined v-model="fromdate"
                            color="primary" readonly v-on="on" dense hide-details></v-text-field>
                        </template>
                        <v-date-picker color="primary" v-model="fromdate" scrollable :max="new Date().toISOString().substr(0, 10)" ></v-date-picker>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <v-menu ref="todateMenu" v-model="todateMenu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field prepend-icon="mdi-calendar-range" outlined label="End" v-model="todate" color="primary"
                            readonly v-on="on" dense hide-details></v-text-field>
                        </template>
                        <v-date-picker color="primary" v-model="todate" scrollable :max="new Date().toISOString().substr(0, 10)" ></v-date-picker>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="fetchall" :loading="isLoading">Fetch Data</v-btn>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols=12 v-if="records.length > 0">
                <v-card>
                    <v-card-title>
                        <v-btn color="success" v-if="records.length > 0" @click="exportexcelfile()">download</v-btn>
                    </v-card-title>
                    <v-data-table item-key="id" loading="true" hidden>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>        
    </v-container>
</template>

<script>
import {SERVER_API} from '../API'
import Excel from 'exceljs';
import Filesaver from 'file-saver';
import {bus} from '../main'
//import Config from '../config'
export default {
    mounted(){
        this.fetchrecords();
    },
    data(){
        return{
            fromdate:'',
            todate:'',
            fromdateMenu:false,
            todateMenu:false,
            isLoading:false,
            product:[],
            records:[],
            products:[],
        }
    },
    methods:{
        async fetchall(){
            try{
            this.isLoading = true;
            const response =  await this.$http.post(SERVER_API+'/bid/report',{
                fromdate: this.fromdate,
                todate: this.todate,
                product: this.product
            });
            this.isLoading = false;
            this.records = response.data;
                if(response.data.length > 0){
                    this.isLoading = false;
                }else{
                    alert('No Data Found');
                }
            }catch(error){
                this.isLoading = false;
                alert(error)
            }
            //console.log(response.data);            
        },
        async fetchrecords(){
            try{
                const response = await this.$http.get(SERVER_API+'/product/records');
                this.products = response.data;
            }catch(error){
                bus.$emit('error',error);
            }
        },

        exportexcelfile(){
            var workbook = new Excel.Workbook();
            workbook.create = 'Test';
            workbook.lastModifiedBy = '';
            workbook.created = new Date(2018, 6, 19);
            workbook.modified = new Date();
            workbook.lastPrinted = new Date(2016, 9, 27);
            var sheet = workbook.addWorksheet('Records');
            sheet.columns = [
                { header: 'Bid Id', key: 'id' },
                { header: 'Bid Date', key: 'createdAt' },
                { header: 'Name of Transportation', key: 'orderBy' },
                { header: 'From', key: 'fromLocation' },
                { header: 'To ', key: 'toLocation' },
                { header: 'Product', key: 'product' },
                { header: 'Qty/tons', key: 'qty' },
                { header: 'Rate', key: 'rate' },
                { header: 'Final Rate', key: 'fRate' },
                { header: 'Final Date', key: 'updatedAt' },
                { header: 'Approve Date', key: 'approvedAt' },
                { header: 'Bid By', key: 'bidBy' },
                { header: 'Vechile No', key: 'vNumber' },
                { header: 'Driver No', key: 'dNumber' },
                { header: 'Vechile Reporting Time', key: 'rTime' },
            ]
            sheet.getRow(1).border = {
                top: {style:'thin', color: {argb:''}},
                left: {style:'thin', color: {argb:'000000'}},
                bottom: {style:'thin', color: {argb:'000000'}},
                right: {style:'thin', color: {argb:'000000'}}
            };
            sheet.getCell('A1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('B1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('C1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('D1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('E1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('F1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('G1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('H1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('I1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('J1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('K1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('L1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('M1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('N1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            sheet.getCell('O1').fill = {
                type: 'pattern',
                pattern:'darkTrellis',
                fgColor:{argb:'FFFFFF00'},
                bgColor:{argb:'FF0000FF'}
            };
            for(var i =0; i <= this.records.length-1;i++ ){              
                sheet.addRow({
                    id : this.records[i].id,
                    createdAt : this.records[i].createdAt,
                    orderBy : this.records[i].orderBy,
                    fromLocation : this.records[i].fromLocation,
                    toLocation : this.records[i].toLocation,
                    product : this.records[i].product,
                    qty : this.records[i].qty,
                    rate : this.records[i].rate,
                    fRate : this.records[i].fRate,
                    updatedAt : this.records[i].updatedAt,
                    approvedAt : this.records[i].approvedAt,
                    bidBy : 'Shree GRG OIL Mill',
                    vNumber : this.records[i].vNumber,
                    dNumber : this.records[i].dNumber,
                    rTime : this.records[i].rTime
                });
            }
            workbook.xlsx.writeBuffer()
            .then(buffer => Filesaver.saveAs(new Blob([buffer]), `${Date.now()}_monthly_report_order.xlsx`));
        },
    }
}
</script>

<style>
.v-speed-dial__list{
    left:auto !important;
    right:50px !important
}
.mt-70{
    margin-top:10%;
}
.router-link-exact-active .v-btn__content{
    color:red !important;
}
</style>