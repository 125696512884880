<template>
  
    <v-img src="/images/grg_background.jpg" style="height:100vh">
    <v-row >
        <v-col md=5 sm=5 lg=4 cols=10 class="elevation-0 pa-0 ma-auto">
            <v-card min-height="100vh" color="transparent" flat>
                    <v-row class="pa-5" align="center" justify="center">
                        <v-row class="pa-5">
                            <v-col cols=12 class="text-center">
                                <v-avatar size="150" color="white" class="elevation-2">
                                    <img src="/images/Logo.png" alt="user" style="width:110px;height:100px;border-radius:unset">
                                </v-avatar>
                            </v-col>
                        </v-row>
                        <v-form @keyup.enter.native="login()">
                            <v-row class="pa-5 text-center" dense>
                                <v-col lg=8 cols=12 class="ma-auto py-0">
                                    <v-text-field v-model="username" label="Username / Email / Phone" outlined prepend-inner-icon="mdi-account" rounded color="black" background-color="white"></v-text-field>
                                </v-col>
                                <v-col lg=8 cols=12 class="ma-auto py-0">
                                    <v-text-field v-model="password" type="password" label="Password" outlined prepend-inner-icon="mdi-key" rounded color="black" background-color="white"></v-text-field>
                                </v-col>
                                <v-col lg=8 cols=21 class="ma-auto py-0">
                                    <v-btn small color="black" block rounded ="" class="elevation-0 amber--text" @click="login()" :loading='isloading'>Sign In</v-btn>
                                </v-col>
                                <v-col lg=8 cols=12 class="ma-auto mt-5">
                                    <router-link :to="{name:'forgot'}"><v-btn small color="black" text >Forgot Password ?</v-btn></router-link>
                                </v-col>
                            </v-row>
                        </v-form>                  
                    </v-row>
            </v-card>
        </v-col>
    </v-row>
    <v-snackbar v-model="notificationBar" top center :color="notificationColor"> {{ notificationText }}
        <v-btn color="amber" text @click="notificationBar = false"> Close </v-btn>
    </v-snackbar>
        
  </v-img>
</template>

<script>
export default {
    data(){
        return{
            username:'',
            password:'',
            isloading:false,
            // Notification Setting
            notificationColor: 'black',
            notificationBar: false,
            notificationText:''
        }
    },
    methods:{
       login() {
                this.isloading = true;
                this.$store.dispatch('retrieveToken', {
                    username: this.username,
                    password: this.password,
                })
                .then(response => {
                    if(response.status == 200){
                        this.notificationColor = 'green';
                        this.notificationBar = true;
                        this.notificationText = response.data.message;
                        location.reload();
                        /*if(this.$store.state.role == 'admin' || this.$store.state.role == 'editor'){
                            this.$routerrouter.go({name: 'dashboard'});
                        }
                        if(this.$store.state.role == 'broker'){
                            this.$routerrouter.go({name: 'customerdashboard'});
                        }*/
                    }else{
                        this.notificationColor = 'red';
                        this.notificationBar = true;
                        this.notificationText = response.data.message;
                    }
                    this.isloading = false;
                }).catch(error => {
                    this.notificationColor = 'red';
                    this.notificationBar = true;
                    this.notificationText = error.response.data.message;
                    this.isloading = false;
                })
            },

    }

}
</script>
