<template>
<v-pagination v-if="lastPage > 1" class="mt-5"
      v-model="currentPage"
      :length="lastPage"
      :total-visible="8"
    ></v-pagination> 
</template>

<script>
    //import {bus} from '../main'
    export default {
        props:['links','meta'],
        data () {
            return{
                currentPage: this.meta.current_page,
                lastPage:this.meta.last_page
            }
        },
        watch:{
            currentPage(newVal){
                this.paginate(newVal);
            }
        },
        methods:{
            paginate(val){
                this.$emit('paginate',val);
            }
        }
    }
</script>

<style>

</style>