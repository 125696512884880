<template>
    <v-card v-if="windowLoad == true">
        <v-skeleton-loader type="article"></v-skeleton-loader>
        <v-skeleton-loader type="article"></v-skeleton-loader>
        <v-skeleton-loader type="actions"></v-skeleton-loader>
    </v-card>
    <v-card v-else>
        <v-app-bar  dark color="blue-grey darken-3" dense flat>
            <v-toolbar-title>
                {{edit == true ? `Edit Product` : `New Product`}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" text fab x-small @click="close()"><v-icon>mdi-close</v-icon></v-btn>
        </v-app-bar>
        <v-card-text class="pt-5">
            <v-text-field v-model="name" label="Product Name" outlined color="blue-grey darken-3" dense></v-text-field>
            <Editor api-key="csbrcr19d8ufakm7p5v5o1mjko213830ocwzb2s5thhq9msv" 
            :init="{ menubar: true,
                plugins: [ 'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'],
                toolbar:'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help'}" v-model="tc"/>
        </v-card-text>
        <v-card-actions class="text--center">
            <v-spacer></v-spacer>
            <v-switch v-model="statusHere" :label="statusHere == true ? 'Active': 'In Active'" :value="true" color="primary"></v-switch>
            <v-btn color="blue-grey darken-3" :loading="isLoading" dark class="mx-5" @click="Save()">Save</v-btn>
        </v-card-actions>
    </v-card>  
</template>

<script>
    import {bus} from '../../main'
    import {SERVER_API} from '../../API'
    import Editor from '@tinymce/tinymce-vue'
    export default {
        components:{Editor},
        props:['id','edit'],
        created(){
            window.addEventListener('keyup', event => {
                if(event.key === 27){
                    this.close();
                }
                if(event.key === 13){
                    this.Save();
                }
            })
        },
        data(){
            return {
                windowLoad:false,
                name:'',
                tc:'',
                status:1,
                statusHere:true,
                isLoading:false,
            }
        },
        watch:{
            statusHere(val){
                if(val == true){
                    this.status = 1;
                }else{
                    this.status = 0;
                }
            },
            checkandfetch(){ 
                //
            }
        },
        computed:{
            checkandfetch(){
                return this.fetchbyId();
            },
        },
        methods:{
            close(){
               this.edit == true ? '' : this.reset(); 
               bus.$emit('close',false);
            },
            reset(){
                this.tc = '';
                this.name = '';
            },
            async fetchbyId(){
                if(this.edit == true){
                    //alert(this.id);
                    try{
                        this.windowLoad = true;
                        const Response = await this.$http.get(SERVER_API+'/product/findbyid/'+this.id);
                        this.name = Response.data.name;
                        this.tc = Response.data.tc;
                        this.status = Response.data.status;
                        this.statusHere = Response.data.status == 0 ? false : true;
                        this.windowLoad = false;
                    }catch(error){
                        this.windowLoad = false;
                        bus.$emit('error',error);
                    }
                }
            },
            Save(){
                this.edit == true ? this.update() : this.create();
            },
            async create(){
                try{
                    this.isLoading = true;
                    const Response = await this.$http.post(SERVER_API+'/product/create',{
                        tc: this.tc,
                        name:this.name,
                        status:this.status
                    });
                    this.isLoading = false;
                    this.reset();
                    bus.$emit('response',{res: Response.data, type:'create', model:'product'});
                    this.close();
                }catch(error){
                    this.isLoading = false;
                    bus.$emit('error',error);
                }
            },
            async update(){
                try{
                    this.isLoading = true;
                    const Response = await this.$http.put(SERVER_API+'/product/update',{
                        id:this.id,
                        tc: this.tc,
                        name:this.name,
                        status:this.status
                    });
                    this.isLoading = false;
                    bus.$emit('response',{res: Response.data, type:'update', model:'product'});
                    this.close();
                }catch(error){
                    this.isLoading = false;
                    bus.$emit('error',error);
                }
            }
        }
    }
</script>

<style>

</style>