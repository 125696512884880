<template>
    <v-container fluid>
        <v-row>
            <v-col lg=4 sm=4 md=4>
                <v-chip label color="blue-grey darken-3" dark>
                    <v-icon left small>mdi-check-all</v-icon>Approved Bids
                </v-chip>
            </v-col>
            <v-col lg=8 sm=12 md=8 cols=12>
                <v-toolbar dense class="elevation-0" right height="35">
                    <v-spacer></v-spacer>
                    <router-link :to="{name:'tdashboard'}"><v-btn color="primary" small class="elevation-0 mx-1" >New Bids</v-btn></router-link>
                    <router-link :to="{name:'tpending'}"><v-btn color="primary" small class="elevation-0 mx-1" >Pending Bids</v-btn></router-link>
                    <router-link :to="{name:'tapproved'}"><v-btn color="primary" small class="elevation-0 mx-1" >Approved Bids</v-btn></router-link>
                    
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="pageLoad == true">
                <v-card>
                    <v-skeleton-loader
                        type="article"
                    ></v-skeleton-loader>
                </v-card>
            </v-col>
            <v-col v-else cols=12>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col cols=12 sm=6 md=8 class="pa-0 ma-0"></v-col>
                            <v-col cols=12 sm=6 md=4 class="py-0 my-0">
                                <v-text-field  ref="name" color="blue-grey darken-3" v-model="search" append-icon="mdi-magnify" label="Search" outlined hide-details dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-data-table :headers="header"  :items="records" item-key="id" :search="search" hide-default-footer>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip color="green" dark small class="text-capitalize font-weight-bold" style="font-size:11px;">{{item.status}}</v-chip>
                        </template>
                        <template v-slot:[`item.detail`]="{ item }">
                            <v-btn v-if="item.vNumber == null" color="green" class="elevation-0" x-small dark fab @click="selectedId = item.id;truckBox=true"><v-icon>mdi-plus</v-icon></v-btn>
                            <v-btn v-else color="green" class="elevation-0" x-small dark fab @click="selectedId = item.id;truckBox=true"><v-icon>mdi-eye</v-icon></v-btn>
                        </template>
                    </v-data-table>
                </v-card>
                <Paginate :links="links" :meta="meta" @paginate="fetchrecordsbypage"/>
            </v-col>
        </v-row>
        <v-dialog v-model="truckBox" persistent max-width="500px" transition="dialog-transition">
            <TruckDetails :id="selectedId"></TruckDetails>
        </v-dialog>
        <!-- Notifications -->
        <v-snackbar v-model="notificationBar" :timeout="10000" top center :color="notificationColor" multi-line class="mt-70"> {{ notificationText }} <v-btn color="white" text fab @click="notificationBar = false"><v-icon>mdi-close</v-icon> </v-btn></v-snackbar>
    </v-container>
</template>

<script>
import { SERVER_API } from '../../API';
import { bus } from '../../main';
import Config from '../../config'
import TruckDetails from '../../components/truck/index.vue'
import Paginate from '../../components/pagination.vue'

export default {
    components:{TruckDetails, Paginate},
    created(){
        bus.$on('notify',() => {
          this.fetchrecords();
      });

        bus.$on('close',() =>  {
            this.truckBox = false;
        });

        bus.$on('error', error =>  {
            //alert('s');
            this.notificationBar = true;
            this.notificationColor = 'red';
            this.notificationText = Config.httpError(error);
        });

        bus.$on('response', data =>  {
            this.notificationBar = true;
            this.notificationColor = data.res.action == true ? 'black' : 'red';
            this.notificationText = data.res.message;
            this.truckBox = false;
        }); 
    },
    mounted(){
        this.fetchrecords();
    },
    data(){
        return{
            meta:[],
            links:[],
            pageLoad: true,
            truckBox:false,
            records:[],
            search:'',
            rate:[],
            updateId:'',
            selectedId:'',
            header:[           
                {text:'Bid Id',value:'id'},     
                {text:'Bid Date',value:'createdAt'},
                {text:'Product',value:'product'},
                {text:'From',value:'fromLocation'},
                {text:'To',value:'toLocation'},
                {text:'Qty / Tons',value:'qty'},
                {text:'Rate',value:'rate'},                
                {text:'Final Rate',value:'fRate'},
                {text:'Final Date',value:'updatedAt'},
                {text:'Approved Date',value:'approvedAt'},
                {text:'status',value:'status'},
                {text:'Vehicle Detail',value:'detail'},
            ],
            //Notifications
            notificationBar:false,
            notificationColor:'black',
            notificationText:''
        }
    },
    methods:{
        getIndex(list, id) {
            return list.findIndex((e) => e.id == id)
        },
        async fetchrecordsbypage(link){ 
            this.pageLoad = true;
            const response = await this.$http.get(SERVER_API+'/bid/approved/byuser/records?page='+link);
            this.records = response.data.data;
            this.meta =  response.data.meta;
            this.links =  response.data.links;
            this.pageLoad = false;
        },
        async fetchrecords(){
            this.pageLoad = true;
            const response = await this.$http.get(SERVER_API+'/bid/approved/byuser/records');
            this.records = response.data.data;
            this.meta =  response.data.meta;
            this.links =  response.data.links;
            this.pageLoad = false;
        },
        async save(){
            try{
                const response = await this.$http.post(SERVER_API+'/bid/approved',{
                    updateId:this.updateId,
                    id: this.selectedId,
                });
                this.notificationBar = true;
                this.notificationColor = response.data.action == true ? 'black' : 'red';
                this.notificationText = response.data.message;
                this.submitBid();
            }catch(error){
                this.notificationBar = true;
                this.notificationColor = 'red';
                this.notificationText = Config.httpError(error);
            }
        },
        submitBid(){
            this.rate.splice(this.getIndex(this.records, this.updateId),1);
            this.records.splice(this.getIndex(this.records, this.updateId),1);
        },
        alreadySubmit(){
            for(let i = 0; i < this.alId.length; i++){
                if(this.records.indexOf(this.alId[i])!= -1){
                    this.records.splice(this.records.indexOf(this.alId[i]),1);
                }
            }
        }
    }

}
</script>