<template>
    <v-container fluid>
        <v-row>
            <v-col lg=4 sm=4 md=4>
                <v-chip label color="blue-grey darken-3" dark>
                    <v-icon left small>mdi-view-dashboard</v-icon>Pending Bids
                </v-chip>
            </v-col>
            <v-col lg=8 sm=12 md=8 cols=12>
                <v-toolbar dense class="elevation-0" right height="35">
                    <v-spacer></v-spacer>
                    <router-link :to="{name:'tdashboard'}"><v-btn color="primary" small class="elevation-0 mx-1" >New Bids</v-btn></router-link>
                    <router-link :to="{name:'tpending'}"><v-btn color="primary" small class="elevation-0 mx-1" >Pending Bids</v-btn></router-link>
                    <router-link :to="{name:'tapproved'}"><v-btn color="primary" small class="elevation-0 mx-1" >Approved Bids</v-btn></router-link>
                    
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="pageLoad == true">
                <v-card>
                    <v-skeleton-loader
                        type="article"
                    ></v-skeleton-loader>
                </v-card>
            </v-col>
            <v-col v-else cols=12>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col cols=12 sm=6 md=8 class="pa-0 ma-0"></v-col>
                            <v-col cols=12 sm=6 md=4 class="py-0 my-0">
                                <v-text-field  ref="name" color="blue-grey darken-3" v-model="search" append-icon="mdi-magnify" label="Search" outlined hide-details dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-data-table :headers="header"  :items="records" item-key="id" :search="search" hide-default-footer>
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn v-if="item.approved == true" color="green" small @click="updateId=item.updateId; selectedId=item.id; tmbox = true;" dark>Approve Now</v-btn>
                            <v-chip v-else color="orange" dark x-small class="text-capitalize font-weight-bold" style="font-size:11px;">{{item.status}}</v-chip>
                        </template>
                    </v-data-table>
                </v-card>
                <Paginate :links="links" :meta="meta" @paginate="fetchrecordsbypage" />
            </v-col>
        </v-row>
        <v-dialog v-model="tmbox" persistent max-width="800px" transition="dialog-transition">
            <v-card>
                <v-toolbar class="elevation-0" height="40">
                    <v-card-title class="subtitle-2">Term & Conditions</v-card-title>
                        <v-spacer></v-spacer>
                     <v-btn color="red" fab x-small text @click="tmbox=false"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text class="pt-5" v-html="termcond"></v-card-text>
                <v-card-actions>
                     <v-checkbox v-model="isAccept" color="primary" class="mt-0" hide-details="" label="Do you accept Terms & Conditions?"></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-btn color="success" :disabled="isDisabled" :loading="apLoading" class="elevation-0" @click="save()">Approve Now</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
        <!-- Notifications -->
        <v-snackbar v-model="notificationBar" :timeout="10000" top center :color="notificationColor" multi-line class="mt-70"> {{ notificationText }} <v-btn color="white" text fab @click="notificationBar = false"><v-icon>mdi-close</v-icon> </v-btn></v-snackbar>
    </v-container>
</template>

<script>
import { SERVER_API } from '../../API';
import { bus } from '../../main';
import Config from '../../config';
import Paginate from '../../components/pagination.vue'

export default {
    components:{
        Paginate
    },
    created(){
        bus.$on('notify',() => {
          this.fetchrecords();
      });
    },
    mounted(){
        this.fetchrecords();
    },
    computed:{
        isDisabled(){
            return this.isAccept == false ? true : false;
        },
    },
    data(){
        return{
            pageLoad:true,
            isAccept:false,
            apLoading:false,
            tmbox:false,
            records:[],
            termcond:'',
            meta:[],
            links:[],
            search:'',
            updateId:'',
            selectedId:'',
            header:[           
                {text:'Bid Id',value:'id'},     
                {text:'Bid Date',value:'createdAt'},
                {text:'Product',value:'product'},
                {text:'From',value:'fromLocation'},
                {text:'To',value:'toLocation'},
                {text:'Qty / Tons',value:'qty'},
                {text:'Rate',value:'rate'},                
                {text:'Final Rate',value:'fRate'},
                {text:'Final Date',value:'updatedAt'},
                {text:'Action',value:'action'},
            ],
            //Notifications
            notificationBar:false,
            notificationColor:'black',
            notificationText:''
        }
    },
    watch:{
        selectedId(){
            this.findCondition();
        }
    },
    methods:{
        getIndex(list, id) {
            return list.findIndex((e) => e.updateId == id)
        },

        async fetchrecordsbypage(link){ 
            this.pageLoad = true;
            const response = await this.$http.get(SERVER_API+'/bid/pending/byuser/records?page='+link);
            this.records = response.data.data;
            this.meta =  response.data.meta;
            this.links =  response.data.links;
            this.pageLoad = false;
        },

        async fetchrecords(){
            const response = await this.$http.get(SERVER_API+'/bid/pending/byuser/records');
            this.records = response.data.data;
            this.meta =  response.data.meta;
            this.links =  response.data.links;
            this.pageLoad = false;
        },
        async save(){
            try{
                this.apLoading = true;
                const response = await this.$http.post(SERVER_API+'/bid/approved',{
                    updateId:this.updateId,
                    id: this.selectedId,
                });
                this.notificationBar = true;
                this.notificationColor = response.data.action == true ? 'black' : 'red';
                this.notificationText = response.data.message;
                this.tmbox=false;
                this.apLoading = false;
                this.isAccept=false,
                this.submitBid();
            }catch(error){
                this.notificationBar = true;
                this.notificationColor = 'red';
                this.notificationText = Config.httpError(error);
            }
        },
        findCondition(){
            this.termcond = this.records.find(x => x.id === this.selectedId).tc;

        },
        submitBid(){
            this.records.splice(this.getIndex(this.records, this.updateId),1);
        },
    }

}
</script>